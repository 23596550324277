import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Components
import FormError from '../common/FormError'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {showFormErrors} from '../../utils/validate'
import DateInput from '../common/DateInput'
import withApi from '../../api/withApi'
import Modal, {ACTION_SUCCESS} from '../common/Modal'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'

const MODAL_CONFIRM_CREATE = 'MODAL_CONFIRM_CREATE'
const MODAL_NONE = ''

class TransactionCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closingDateError: '',
      createdTransaction: false,
      error: '',
      loginToken: checkToken(this),
      transactionId: '',
      transactionName: '',
      closingDate: '',
      modal: MODAL_NONE,
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseCreate()
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({modal: MODAL_CONFIRM_CREATE})
  }

  createTransaction = () => {
    let closingDate = this.state.closingDate
    closingDate = new Date(closingDate.replace(/-/g, '/'))
    const today = new Date().setHours(0, 0, 0, 0)

    if (!showFormErrors(this) || closingDate < today) {
      let closingDateError = ''

      if (closingDate < today) {
        closingDateError = 'Transaction date must be today or later.'
      } else {
        closingDateError = ''
      }

      this.setState({
        closingDateError: closingDateError,
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      const {transactionId, transactionName, closingDate} = this.state
      const companyId = this.state.userAccount.company.id

      this.props.api
        .transactionCreate(companyId, {
          guid: transactionId,
          name: transactionName,
          closing_date: closingDate,
        })
        .then((response) => {
          this.setState({
            closingDateError: '',
            error: '',
          })

          this.props.handleDataRefresh()
          this.props.handleCloseCreate()
        })
        .catch((error) => {
          this.setState({
            modal: MODAL_NONE,
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: false})
        })
    }
  }

  render() {
    //const {cost, currency} = this.props.pricing.transaction
    let cost, currency
    if (this.props.pricing) {
      cost = this.props.pricing.transaction.cost
      currency = this.props.pricing.transaction.currency
    }
    const decimalCost = cost / 100

    const feePopupMessage = `Creating a transaction will result in a fee of $${decimalCost} ${_.upperCase(
      currency,
    )} (plus tax - if applicable) being charged to your account.`

    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Create Transaction</h5>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <div className="form__group">
                        <div className="row">
                          <label id="transactionIdLabel">Transaction ID</label>
                        </div>
                        <input
                          type="text"
                          name="transactionId"
                          ref="transactionId"
                          className="form__control"
                          value={this.state.transactionId}
                          onChange={this.handleInput}
                          placeholder="Enter Transaction ID"
                          title="Transaction ID"
                          required
                        />
                        <div className="error" id="transactionIdError" />
                      </div>

                      <div className="form__group">
                        <div className="row">
                          <label id="transactionNameLabel">
                            Transaction Name
                          </label>
                        </div>
                        <input
                          type="text"
                          name="transactionName"
                          ref="transactionName"
                          className="form__control"
                          value={this.state.transactionName}
                          onChange={this.handleInput}
                          placeholder="Enter transaction name"
                          title="Transaction Name"
                          required
                        />
                        <div className="error" id="transactionNameError" />
                      </div>

                      <DateInput
                        id="closingDate"
                        title="Transaction Date"
                        date={this.state.closingDate}
                        isEditing={true}
                        required={true}
                        onChange={(e, id, value) =>
                          this.setState({[id]: value})
                        }
                      />
                      <div className="error">{this.state.closingDateError}</div>

                      <FormError error={this.state.error} />
                      <div className="form__group style--1 flexbox space-between">
                        <span className="col-60 left">
                          Creating a transaction will result in a fee of $
                          {decimalCost} {_.upperCase(currency)} (plus tax - if
                          applicable) being charged to your account. This
                          transaction will automatically close 5 days following
                          the assigned transaction date.
                        </span>
                        <div className="flexbox vertical-center">
                          <button
                            type="submit"
                            className="col-40 btn btn--mod-2 no-margin"
                            value="Create"
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.modal === MODAL_CONFIRM_CREATE && (
          <Modal
            title="Are you sure?"
            message={feePopupMessage}
            error={null}
            action="Accept"
            actionType={ACTION_SUCCESS}
            disabled={this.state.networkActive}
            onCancel={() => this.setState({modal: MODAL_NONE})}
            onAction={() => this.createTransaction()}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  pricing: state.preferences.pricing,
})

export default withApi(withRouter(connect(mapStateToProps)(TransactionCreate)))
